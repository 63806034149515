/*
Theme Name: Dummy
Theme URI: http://beddbdigital.com
Description: This Theme is designed for Dummy. All rights Reserved By Dummy.
Version: 1.0
Author: BE DDB Digital
Author URI: http://beddbdigital.com
*/

@import url("css/bootstrap.css");
@import url("css/master.css");